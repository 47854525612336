<template>
  <div ref="print" class="ExportReport">
    <el-button
      type="primary"
      class="no-print"
      @click="print"
    >{{$t('restaurant_buy_print.945C82@Print_immediately')}}</el-button>
    <section class="header">
      <h2>{{reportData.supplier_name}}</h2>
      <div>{{reportData.date}}</div>
    </section>

    <section>
      <el-table
        border
        class="mt20"
        :row-class-name="$tableRowClassName"
        :data="reportData.list"
        :highlight-current-row="true"
        :element-loading-text="$t('public.4ABAB1@loading')"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="date" :label="$t('aboutus.381E69@Date')"></el-table-column>
        <el-table-column prop="order_no" :label="$t('placeholder.5C894A@order_code')"></el-table-column>
        <el-table-column prop="restaurant_name" :label="$t('aboutus.E8221A@restaurant_name')"></el-table-column>
        <el-table-column prop="amount" :label="$t('aboutus.FE0DEB@Amount')" width="150"></el-table-column>
        <template slot="append" v-if="reportData.total_amount>0">
          <div class="total_amount">
            {{$t('aboutus.CBD374@total_price')}}&nbsp;:
            <span>{{reportData.total_amount}}</span>
          </div>
        </template>
      </el-table>
    </section>
    <!-- <section class="footer">Accrual basis Monday. 2 March 2020 01:45 PM GMT-08:00</section> -->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "",
  components: {},
  data() {
    //这里存放数据
    return {
      params: {
        start_time: "",
        end_time: "",
        restaurant_id: ""
      },
      reportData: "",
      total_amount: ""
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getData() {
      this.$http.exportAccountantPDF(this.params).then(res => {
        this.reportData = res.data;
      });
    },
    print() {
      if (
        navigator.userAgent.indexOf("Edge") > -1 ||
        navigator.userAgent.indexOf("Chrome") == -1
      ) {
        this.$confirm(
          "想要体验最佳效果，建议请使用谷歌游览器(Chrome)打印, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(() => {
            this.$print(this.$refs.print);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消打印"
            });
          });
      } else {
        this.$print(this.$refs.print);
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    Object.assign(this.params, this.$route.params);
    this.getData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss'>
//@import url(); 引入公共css类
.ExportReport {
  width: 900px;
  margin: 0 auto;
  min-height: 800px;
  border: 1px solid #f6f6f6;
  padding: 60px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .header {
    height: 170px;
    overflow: hidden;
    text-align: center;
    h1 {
      margin-bottom: 20px;
    }
    .title {
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .branch {
      margin: 5px 0;
      font-size: 16px;
    }
  }
  .main {
    border-bottom: 3px double black;
    .section {
      div.name {
        font-weight: 600;
        margin: 10px 0;
        font-size: 16px;
      }
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        margin: 8px 0;
        color: #000;
      }
    }
    .total {
      font-weight: 700;
      font-size: 20px;
      height: 50px;
      line-height: 50px;
      border-top: 1px solid #7d7a7a;
      border-bottom: 1px solid #7d7a7a;
      text-align: right;
    }
    i.border {
      width: 100%;
      display: block;
      height: 1;
      background-color: #d1d1d1;
    }
  }
  .total_amount {
    padding: 10px 30px;
    text-align: right;
    font-size: 14px;
    span {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #f24040;
    }
  }
  .footer {
    border-top: 3px double black;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>